import { LocationService } from './location.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TranslateModule, } from '@ngx-translate/core';
import { FeatureRequired } from '../shared/account-guards';
import { SessionRequired } from '../shared/session-guards';
import { SessionService } from '../shared/session.service';
import { ChartColorService } from '../shared/chart-colors.service';
import { ChartLabelService } from '../shared/chart-label.service';
import { StateService } from '../shared/state.service';
import { ReloadRequired } from '../shared/reload-guard';
import { BusinessCitationsComponent } from './business-citations/business-citations.component';
import { BusinessCitationsSyncHistoryComponent } from './business-citations-sync-history/business-citations-sync-history.component';
import { LocationCardComponent } from './business-citations/location-card/location-card.component';
import { LocationMapComponent } from './business-citations/location-map/location-map.component';
import { LocationBusinessCitationsComponent } from './business-citations/location-business-citations/location-business-citations.component';
import { GoogleViewsByDateComponent } from './business-citations/google-views-by-date/google-views-by-date.component';
import { GoogleActionsByDateComponent } from './business-citations/google-actions-by-date/google-actions-by-date.component';
import { GoogleMyBusinessStatusComponent } from './business-citations/google-my-business-status/google-my-business-status.component';
import { AngularOpenlayersModule } from 'ng-openlayers';
import { Features } from '../shared/models/features.enum';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BaseChartDirective } from 'ng2-charts';

const routes: Routes = [{
    path: 'business-citations',
    canActivateChild: [ReloadRequired],
    children:
        [
            {
                path: 'local-listings',
                component: BusinessCitationsComponent,
                canActivate: [SessionRequired, FeatureRequired],
                data: { requiredFeature: Features.BusinessCitations }
            },
            {
                path: 'listings-sync-history',
                component: BusinessCitationsSyncHistoryComponent,
                canActivate: [SessionRequired, FeatureRequired],
                data: { requiredFeature: Features.BusinessCitations }
            }
        ]
}];

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        AngularOpenlayersModule,
        SharedModule,
        RouterModule.forRoot(routes, {}),
        BaseChartDirective
    ],
    providers: [
        LocationService,
        SessionService,
        SessionRequired,
        FeatureRequired,
        LocationService,
        ChartColorService,
        ChartLabelService,
        StateService,
        ReloadRequired
    ],
    declarations: [
        BusinessCitationsComponent,
        BusinessCitationsSyncHistoryComponent,
        LocationCardComponent,
        LocationMapComponent,
        LocationBusinessCitationsComponent,
        GoogleViewsByDateComponent,
        GoogleActionsByDateComponent,
        GoogleMyBusinessStatusComponent
    ],
    exports: [
        BusinessCitationsComponent,
        BusinessCitationsSyncHistoryComponent
    ]
})
export class BusinessCitationsModule { }
