import { LocationService } from '../location.service';
import { LocationBusinessCitation } from '../models/location-business-citation';
import { BaseComponent } from '../../base.component';
import { Component, OnInit, signal, computed } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { SessionService } from '../../shared/index';
import { StateService } from '../../shared/state.service';
import { State, Account } from '../../shared/models';
import { takeUntil } from 'rxjs/operators';
import { ChartData } from '../../shared/models/chartData';
import * as moment from 'moment';

const DEFAULT_SELECTED_DAYS = 90;
const DATA_DELAY_DAYS = 7;
const PAST_DAYS_SELECTION = [30, 90, 365];

@Component({
  selector: 'app-business-citations',
  templateUrl: './business-citations.component.html',
  styleUrls: ['./business-citations.component.scss'],
  providers: [TranslatePipe]
})
export class BusinessCitationsComponent extends BaseComponent implements OnInit {

  public selectedLocationBusinessCitation: LocationBusinessCitation;
  public locationBusinessCitations: LocationBusinessCitation[] = [];
  public states: State[];
  public account: Account;
  public maxDate: Date = moment().add(-DATA_DELAY_DAYS, 'days').toDate();
  public defaultSelectedDays = DEFAULT_SELECTED_DAYS;
  public pastDaysSelection = PAST_DAYS_SELECTION;
  public graphSubtitle: string;
  public rangeStartLabel = signal(moment().add(-DEFAULT_SELECTED_DAYS, 'days').format('L'));
  public rangeEndLabel = signal(moment(this.maxDate).format('L'));
  public googleRangeLabel = computed(() => `${this.rangeStartLabel()} - ${this.rangeEndLabel()}`);
  public hasAnyData: boolean = false;

  constructor(private locationService: LocationService,
    private stateService: StateService,
    public sessionService: SessionService) {
    super();
  }

  ngOnInit() {
    this.locationService
      .getLocationBusinessCitations()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(locationBusinessCitations => {
        this.locationBusinessCitations = locationBusinessCitations;

        if (this.locationBusinessCitations.length > 0) {
          this.selectedLocationBusinessCitation = this.locationBusinessCitations[0];
        }

        this.fixFirstLabels(moment().add(-DEFAULT_SELECTED_DAYS, 'days').toDate().toLocaleDateString());
        this.updateHasAnyData();
      });

    this.stateService
      .getStates()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(states => {
        this.states = states;
      });

    this.sessionService.account
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(account => {
        this.account = account;
      });
  }

  analyticsPeriodChanged([start, end]: [Date, Date]): void {
    // If start is null, get all data since the beginning of time (also known as January 1st, 1970)
    this.locationService
      .getLocationAnalytics(start ?? new Date(0), end)
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(analytics => {
        analytics.forEach(x => {
          this.locationBusinessCitations.find(y => y.location.id === x.locationId).analytics = x;
        });

        if (start) {
          this.fixFirstLabels(start.toLocaleDateString());
        }

        this.rangeStartLabel.set((moment(this.selectedLocationBusinessCitation.analytics.googleViewsByDate.googleViewsMetrics.labelKeys[0])).format('L'));
        this.rangeEndLabel.set(moment(end.toLocaleDateString()).format('L'));
      });
  }

  rangeCleared(): void {
    this.analyticsPeriodChanged([null, this.maxDate]);
  }

  updateHasAnyData(): void {
    this.hasAnyData = this.selectedLocationBusinessCitation.analytics.googleActionsByDate.googleActionsMetrics.hasData
      || this.selectedLocationBusinessCitation.analytics.googleViewsByDate.googleViewsMetrics.hasData;
  }

  /// <summary>
  /// Fixes the first label of the chart data to the given label.
  /// Necessary since data grouped by weeks/months uses the first day of that period, even if it's not included in the range.
  /// </summary>
  private fixFirstLabels(firstLabel: string): void {
    this.locationBusinessCitations.filter(x => x.analytics.googleActionsByDate.googleActionsMetrics.hasData).forEach(x => {
      this.fixFirstLabel(x.analytics.googleActionsByDate.googleActionsMetrics, firstLabel);
    });
    this.locationBusinessCitations.filter(x => x.analytics.googleViewsByDate.googleViewsMetrics.hasData).forEach(x => {
      this.fixFirstLabel(x.analytics.googleViewsByDate.googleViewsMetrics, firstLabel);
    });
  }

  private fixFirstLabel(data: ChartData, firstLabel: string): void {
    if (data.labelKeys[0] !== firstLabel) {
      data.labelKeys[0] = firstLabel;
    }
  }
}
